import React from "react";
import {useFormContext} from "react-hook-form";
import styled from "styled-components";
import T from "../texts/T";
import Input from "./Input";
import TextArea from "./TextArea";

const InputStyled = styled.div`
    display: flex;
    flex-direction: column;

    p {
        margin-top: 5px;
        margin-bottom: 0px;
    }
`;

export const InputWithLabel = ({
                                   label,
                                   name,
                                   value,
                                   second,
                                   rows,
                                   className,
                                   validate = {},
                                   hideErrorLabel,
                                   ...props
                               }) => {
    const {
        register,
        formState: {errors},
    } = useFormContext();

    return (
        <InputStyled className={className}>
            <LabelStyled htmlFor={name} hidden={props.hidden}>
                <T second={second}>{`${label} ${
                    validate.hasOwnProperty("required") || props.required ? " *" : ""
                }`}</T>
            </LabelStyled>
            {(!rows || rows === 1) && (
                <Input
                    aria-invalid={errors[name] ? "true" : "false"}
                    {...register(name, validate)}
                    value={value}
                    {...props}
                />
            )}
            {rows > 1 && (
                <TextArea
                    name={name}
                    {...register(name, validate)}
                    rows={rows}
                    value={value}
                    {...props}
                />
            )}
            {!hideErrorLabel &&
                (name in errors ? (
                    <p role={"alert"}>
                        {errors[name].message}
                    </p>
                ) : (
                    <p>&nbsp;</p>
                ))}
        </InputStyled>
    );
};

export default InputWithLabel;

const LabelStyled = styled.label`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
