import React from 'react';
import './App.css';
import {Dropzone} from "./Dropzone";
import InputWithLabel from "./components/inputs/InputWithLabel";
import {FormProvider, useForm} from "react-hook-form";
import theme from "./theme";
import styled, {ThemeProvider} from "styled-components";
import Footer from "./components/layout/Footer";
import NotificationContainer from "./components/notifications/NotificationContainer";

function App() {
    const methods = useForm();

    const username = methods.watch("username");
    const password = methods.watch("password");

    const onUploaded = (filename) => {
        methods.reset({username: "", password: ""});
    }

    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                <FormProvider {...methods}>
                    <h1>Busy til Tripletex</h1>
                    <InputContainer>
                        <InputWithLabel label={"Brukernavn"} name={"username"}/>
                        <InputWithLabel label={"Passord"} name={"password"} type={"password"}/>
                    </InputContainer>
                    <Dropzone username={username} password={password} onUploaded={onUploaded}/>
                </FormProvider>
            </div>
            <Footer>
                Grensesnitt
            </Footer>
            <NotificationContainer/>
        </ThemeProvider>

    );
}

const InputContainer = styled.div`
    position: relative;
    max-width: 20rem;
`;

export default App;
