import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import {toast} from "react-hot-toast";
import {API_BASE_URL} from "./constants";

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    cursor: 'pointer',
    transition: 'border .24s ease-in-out',
    height: '100%',
    justifyContent: 'center'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

export const Dropzone = ({onUploading, onUploaded, username, password}) => {
    const [files, setFiles] = useState([]);
    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles.length > 1) {
            return toast('Kun 1 fil omgangen');
        }
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
    }, []);
    const {
        isDragActive,
        getRootProps,
        getInputProps,
        isDragReject,
        isDragAccept
    } = useDropzone({
        onDrop,
        accept: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [".xlsx"]
        },
        minSize: 0,
    })

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));

        // Send files
        files.forEach(file => {
            uploadFile(file);
        })
    }, [files]);

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject
    ]);

    const uploadFile = async (file) => {
        console.log("Sending file", file);
        if (onUploading)
            onUploading(true);
        const fd = new FormData();

        fd.append("username", username);
        fd.append("password", password);
        fd.append('file', file);
        fetch(`${API_BASE_URL ? API_BASE_URL : ""}/api/upload`, {
            // content-type header should not be specified!
            method: 'POST',
            body: fd,
            credentials: 'include',
        })
            .then(response => response.json())
            .then(success => {
                console.log("Success", success);
                if ('data' in success && success.data) {
                    toast.success("Timelisten er importert")
                    if (onUploaded)
                        onUploaded(file.name)
                    if (onUploading)
                        onUploading(false);
                } else {
                    files.forEach(file => URL.revokeObjectURL(file.preview));
                    setFiles([]);
                    if (onUploading)
                        onUploading(false);
                    toast.error(success?.message ? success.message : 'Feil ved opplasting av timelisten');
                    if (success?.entry?.comment) {
                        toast.error(`${success?.entry?.date} ${success?.entry?.hours} ${success.entry.comment}`)
                    }
                    if (onUploaded)
                        onUploaded(null);
                }
            })
            .catch(error => {
                    console.log(error)
                toast.error('Feil ved opplasting av timelisten');
                    if (onUploading)
                        onUploading(false);
                    if (onUploaded)
                        onUploaded(null);
                }
            );
    }

    return (
        <section className="container">
            <div {...getRootProps({style})}>
                <input {...getInputProps()} />
                {!isDragActive && 'Klikk her eller slipp en fil for å laste opp.'}
                {isDragActive && !isDragReject && "Slipp for å laste opp!"}
                {isDragReject && "Filtypen er ikke akseptert, beklager!"}
                <em>(Kun *.xlsx filer eksportert fra Busy er akseptert)</em>
            </div>
        </section>
    )
}