import React from "react";
import styled from "styled-components";
import H1 from "../texts/H1";

const StyledFooter = styled.footer`
    width: 100%;
    height: 20px;
    color: ${(props) => props.theme.footer.text.color};
    padding: 5px 0 17px 12px;
    z-index: 20;

    > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

const H1S = styled(H1)`
    font-size: 12px;
    margin-top: -5px;
    margin-bottom: 0;
`;

const Footer = () => (
    <StyledFooter id={"footer"}>
        <div>
            <H1S>Grensesnitt</H1S>
        </div>
    </StyledFooter>
);

export default Footer;
